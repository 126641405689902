import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login';
import Auth from '../views/Auth';
import Treaty from '../views/Treaty';
import { useStore } from '@/store';

const routes = [
    {
        path: '',
        redirect: '/auth',
        meta: {
            needLogin: false
        }
    },
    {
        path: '/auth',
        name: 'auth',
        meta: {
            title: '微信登录',
            needLogin: false
        },
        component: Auth
    },
    {
        path: '/treaty',
        name: 'treaty',
        meta: {
            title: '用户服务协议',
            needLogin: false
        },
        component: Treaty
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
            needLogin: false
        },
        component: Login
    },
    {
        path: '/index',
        name: 'index',
        meta: {
            title: '活动',
            needLogin: true
        },
        component: () =>
            import(/* webpackChunkName: "index" */ '../views/TabBarPage')
    },
    {
        path: '/detail',
        name: 'detail',
        meta: {
            title: '活动详情',
            needLogin: false
        },
        component: () =>
            import(/* webpackChunkName: "index" */ '../views/Detail')
    },
    {
        path: '/signUp',
        name: 'signUp',
        meta: {
            title: '填写报名信息',
            needLogin: false
        },
        component: () =>
            import(/* webpackChunkName: "index" */ '../views/SignUp')
    },
    {
        path: '/recordList',
        name: 'recordList',
        meta: {
            title: '报名名单',
            needLogin: true
        },
        component: () =>
            import(/* webpackChunkName: "index" */ '../views/RecordList')
    },
    {
        path: '/set',
        name: 'set',
        meta: {
            title: '设置',
            needLogin: true
        },
        component: () => import(/* webpackChunkName: "my" */ '../views/Set')
    },
    {
        path: '/changePwd',
        name: 'changePwd',
        meta: {
            title: '修改密码',
            needLogin: true
        },
        component: () =>
            import(/* webpackChunkName: "my" */ '../views/ChangePwd')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
router.beforeEach((to, from, next) => {
    const store = useStore();
    if (!store.isWxBrowser()) {
        if (to.query.adminId) {
            next();
            return;
        }
        if (to.path === '/auth') {
            next();
        } else {
            next('/auth');
        }
        return;
    }
    document.title = to.meta.title || '';
    if (store.token || !to.meta.needLogin) {
        next();
        return;
    }
    next('/login');
});
export default router;
